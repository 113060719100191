/* eslint-disable @typescript-eslint/ban-ts-comment,max-classes-per-file,@typescript-eslint/no-explicit-any */

import { EditOutlined }                      from '@ant-design/icons';
import { PlusOutlined }                      from '@ant-design/icons';
import { ArrowRightOutlined }                from '@ant-design/icons';
import { ArrowLeftOutlined }                 from '@ant-design/icons';
import { SaveOutlined }                      from '@ant-design/icons';
import { CloseOutlined }                     from '@ant-design/icons';
import Button                                from 'antd/lib/button';
import Spin                                  from 'antd/lib/spin';
import Steps                                 from 'antd/lib/steps';
import classNames                            from 'classnames';
import AbstractForm                          from 'components/AbstractForm/AbstractForm';
import AbstractLightForm                     from 'components/AbstractForm/AbstractLightForm';
import AppModal                              from 'components/AppModal';
import { IFormModalProps }                   from 'components/FormModal/FormModal';
import { closeAllTooltips }                  from 'components/Tooltip';
import View                                  from 'components/View';
import _remove                               from 'lodash/remove';
import AbstractApiModel                      from 'modelx/models/abstracts/AbstractApiModel';
import ReactDOM                              from 'react-dom';
import Draggable                             from 'react-draggable';
import React                                 from 'react';
import browserHistory                        from 'tools/browserHistory';
import Modal                                 from './Modal/Modal';

// @ts-ignore
export type FormStoreItemProps<T> = Omit<React.ComponentProps<T>, 'onChangeIsSubmitting'>;

type FormStoreItem = { FormComponent: typeof AbstractForm<any>; formInstance: FormInstance; }

export let contextModal;

export const setContextModal = m => contextModal = m;

class FormInstance {
	public formProps = {} as any;
	public modalProps: Omit<IFormModalProps, 'children'> = {};
	private _abstractFormRef = React.createRef<AbstractForm<any>>();

	private _ref;

	constructor(FormComponent, modalProps, props, onEnd: (v: boolean) => void) {
		const { hideOkButton } = modalProps;

		const model = props['model'];
		if (typeof modalProps?.title === 'undefined' && model instanceof AbstractApiModel) {
			if (model.id) {
				const label = model.modelLabel === model.id ? '' : model.modelLabel;
				modalProps.title = (
					<View centerV gap={10} row>
						<EditOutlined style={{ fontSize: 18 }} />
						Modifier {model.theStaticLabel.toLowerCase()}{label ? ` : ${label}` : ''}
					</View>
				);
			} else {
				modalProps.title = (
					<View centerV gap={10} row>
						<PlusOutlined style={{ fontSize: 18 }} />
						Ajouter {model.aStaticLabel.toLowerCase()}
					</View>
				);
			}
		}

		this.formProps = props;

		this.modalProps = {
			...Modal.defaultProps,

			className: classNames('FormModal', { hideOkButton }),
			closable: true,
			content: this.renderContent(FormComponent, modalProps, props, false, onEnd),
			icon: null,
			modalRender: modal => <DragModal>{modal}</DragModal>,
			okText: `Enregistrer`,
			// eslint-disable-next-line
			onOk: _close => this.submit(), // Il est nécessaire de laisser le paramètre "close".
			wrapClassName: `app-form-modal${modalProps.footer === null ? ' no-footer' : ''}`,

			...modalProps,
			// eslint-disable-next-line
			onCancel: _close => { // Il est nécessaire de laisser le paramètre "close".
				formStore.close(FormComponent);
				onEnd(false);
				if (modalProps.onCancel) {
					modalProps.onCancel();
				}
			},
		};

		if (contextModal) {
			this._ref = contextModal.confirm(this.modalProps);
		}

		setTimeout(() => this.focusFirstField(), 50);
	}

	public close() {
		this._ref.destroy();
	}

	public focusFirstField() {
		// @ts-ignore
		// eslint-disable-next-line react/no-find-dom-node
		ReactDOM.findDOMNode(this._abstractFormRef.current)?.querySelector('input, textarea, select')?.focus();
	}

	public renderContent(FormComponent, modalProps, props, loading: boolean, onEnd: (v: boolean) => void) {
		const { hideOkButton } = modalProps;

		return modalProps.loading ? <Spin className="app-centered-spin" /> : (
			<div onKeyDown={e => {
				if (
					e.key === 'Enter'
					&& e.target
					&& !(e.target instanceof HTMLTextAreaElement)
					&& e.target['role'] !== 'textbox'
				) {
					e.preventDefault();
					this.submit();
				}
			}}>
				<Spin size="large" spinning={loading}>
					<FormComponent
						onChangeIsSubmitting={submitting => this._ref.update({
							className: classNames('FormModal', { hideOkButton, loading: submitting }),
							content: this.renderContent(FormComponent, modalProps, props, submitting, onEnd),
							okButtonProps: { disabled: submitting, loading: submitting },
						})}
						onDisableSubmitButton={value => this._ref.update({ okButtonProps: { disabled: value } })}
						ref={this._abstractFormRef}
						{...props}
						onSuccess={async (...args) => {
							formStore.close(FormComponent);

							onEnd(true);

							if (props.onSuccess) {
								props.onSuccess(...args);
							}
						}}
					/>
				</Spin>
			</div>
		);
	}

	public submit() {
		return this._abstractFormRef.current?.submit();
	}
}

type WizardProps = {
	initialStepIndex?: number;
	process: FormWizard;
}

type ExtraSubmit = {
	label: string;
	submit: () => void;
}

type FormWizardStep<T extends typeof AbstractForm> = {
	extraSubmit?: ExtraSubmit;
	form: T;
	formProps: ((process: FormWizard) => FormStoreItemProps<T>) | FormStoreItemProps<T>;
	hideOkButton: boolean;
	required: boolean;
	submitLabel?: string;
	submitted: boolean;
	title: string;
}

const WizardForm = (props: WizardProps) => {
	const { process } = props;
	const [v, setV] = React.useState(0);
	const [nextButtonIsDisabled, setNextButtonIsDisabled] = React.useState(false);
	const [navigationItemsAreDisabled, setNavigationItemsAreDisabled] = React.useState(false);
	const [currentStepIndex, setCurrentStepIndex] = React.useState(props.initialStepIndex || 0);
	const formRef = React.useRef<AbstractForm<AbstractApiModel>>();
	const previousStepIndex = React.useRef(-1);
	const currentFormProps = React.useRef({});

	const currentStep = process.steps[currentStepIndex];
	const nextStep = process.steps[currentStepIndex + 1];
	const prevStep = process.steps[currentStepIndex - 1];
	const FormComponent = currentStep.form;

	// On regénère les props du formulaire quand l'étape change
	if (currentStepIndex !== previousStepIndex.current) {
		const { formProps } = currentStep;
		previousStepIndex.current = currentStepIndex;

		currentFormProps.current = typeof formProps === 'function' ? formProps(process) : formProps;

		if (!currentFormProps.current['model']) {
			currentFormProps.current['model'] = new FormComponent['modelClass']();
		}
	}

	const finalFormProps = currentFormProps.current;

	const goToNext = () => nextStep ? setCurrentStepIndex(currentStepIndex + 1) : process.close();
	const goToPrev = () => prevStep ? setCurrentStepIndex(currentStepIndex - 1) : process.close();

	const isSubmitting = !!formRef.current?.isSubmitting;
	const forceUpdate = () => setV(v + 1);
	process.forceUpdate = forceUpdate;

	const submit = async () => {
		const validation = await formRef.current?.formRef.current?.validateFields();
		if (!validation.errorFields) {
			try {
				formRef.current?.setSubmitting(true);
				const res = await formRef.current?.['onSubmit']();
				if (res !== false) {
					currentStep.submitted = true;
				}
			} catch (err) {
				formRef.current?.['_onError'](err);
			} finally {
				formRef.current?.setSubmitting(false);
			}
			// debugger;

			// currentStep.submitted = true;
		}
	};

	const items = process.steps.map((step, idx) => ({
		description: (!step.submitted && currentStepIndex > idx) ? `Non enregistré` : undefined,
		disabled: (
			navigationItemsAreDisabled
			|| isSubmitting
			|| (currentStep.required && (idx > currentStepIndex) && !currentStep.submitted)
			|| process.steps.some((s, i) => i < idx && s.required && !s.submitted)
		),
		status: idx === currentStepIndex ? 'process' : (step.submitted ? 'finish' : 'wait') as any,
		subTitle: step.required ? undefined : '(Optionnel)',
		title: step.title,
	}));

	const onChange = async stepIdx => {
		if (stepIdx > currentStepIndex) {
			const nextRequiredStepIndex = process.steps.findIndex((s, i) => {
				return (
					i > currentStepIndex
					&& s.required
					&& i < stepIdx
					&& !s.submitted
				);
			});
			if (nextRequiredStepIndex !== -1) {
				setCurrentStepIndex(nextRequiredStepIndex);
			} else {
				setCurrentStepIndex(stepIdx);
			}
		} else {
			setCurrentStepIndex(stepIdx);
		}
	};

	return (
		<View minHeight={600} spread>
			<View
				absR={20}
				absT={18}
				color="rgba(0,0,0,0.45)"
				hover={{ color: 'rgba(0,0,0,0.8)' }}
				onClick={process.close.bind(process)}
				pointer
				size={16}
				style={{ zIndex: 1 }}
			>
				<CloseOutlined />
			</View>

			<Steps
				className="modal-steps"
				current={currentStepIndex}
				items={items}
				onChange={onChange}
				type="navigation"
			/>

			<View flex>
				<FormComponent
					ref={formRef as any}
					{...finalFormProps}
					onChangeIsSubmitting={() => {
						forceUpdate();

						if (finalFormProps['onChangeIsSubmitting']) {
							finalFormProps['onChangeIsSubmitting']();
						}
					}}
					onDisableNavigationItems={value => setNavigationItemsAreDisabled(value)}
					onDisableNextButton={value => setNextButtonIsDisabled(value)}
					onSuccess={async (...args) => {
						process.setHasSucceed();

						goToNext();

						if (finalFormProps['onSuccess']) {
							finalFormProps['onSuccess'](...args);
						}
					}}
				/>
			</View>

			<View className="modal-btns" row spread>
				<View gapH={10} row>
					<Button onClick={() => process.close()}>Fermer</Button>
					{!!prevStep && prevStep.formProps['model'] && (
						<Button icon={<ArrowLeftOutlined />} loading={isSubmitting} onClick={goToPrev}>
							Précédent
						</Button>
					)}
				</View>

				<View gapH={10} row>

					{(!currentStep.required || currentStep.submitted) && !!nextStep && (
						<Button disabled={nextButtonIsDisabled} icon={<ArrowRightOutlined />} loading={isSubmitting} onClick={goToNext}>Suivant</Button>
					)}
					{currentStep.extraSubmit && !currentStep.hideOkButton && <Button icon={<SaveOutlined />} loading={isSubmitting} onClick={async () => {
						await submit();
						currentStep.extraSubmit?.submit();
					}} type="primary">
						{currentStep.extraSubmit.label}
					</Button>}

					{!currentStep.hideOkButton && <Button icon={<SaveOutlined />} loading={isSubmitting} onClick={submit} type="primary">
						{currentStep.submitLabel ? currentStep.submitLabel : nextStep ? `Enregistrer et suivant` : `Enregistrer`}
					</Button>}
				</View>
			</View>
		</View>
	);
};

class FormWizard {
	public forceUpdate;
	private _hasSucceed = false; // Est-ce qu'une étape est déjà passé dans son "onSuccess"
	private _modal;
	private _onOpen?: () => void;
	private _onSuccess?: () => void;
	private _resolve;
	private _steps: FormWizardStep<typeof AbstractForm>[] = [];
	private readonly _title;

	public constructor(title: string) {
		this._title = title;
	}

	public addStep<T extends typeof AbstractLightForm>(
		form: T,
		formProps: FormWizardStep<T>['formProps'],
		options?: { disableNextButton?: boolean; extraSubmit?: ExtraSubmit; hideOkButton?: boolean; required?: boolean; submitLabel?: string; title?: string; },
	) {
		this._steps.push({
			extraSubmit: options?.extraSubmit,
			form,
			formProps,
			hideOkButton: !!options?.hideOkButton,
			required: !!options?.required,
			submitLabel: options?.submitLabel,
			submitted: !!formProps['model']?.id,
			title: options?.title || formProps['model']?.staticLabel || form.modelClass?.staticLabel || '',
		});

		if (this.forceUpdate) {
			this.forceUpdate();
		}

		return this;
	}

	public close() {
		this._resolve();
		this._modal?.destroy();

		if (this._hasSucceed && this._onSuccess) {
			this._onSuccess();
		}
	}

	public onOpen(callback: () => void) {
		this._onOpen = callback;
		return this;
	}

	/**
	 * On renseigne la méthode qui sera appelée à la fermeture de la modale
	 * si au moins une des étapes est passée dans le "onSuccess" d'un "Form"
	 */
	public onSuccess(callback: () => void) {
		this._onSuccess = callback;
		return this;
	}

	public open(modalProps?: Omit<IFormModalProps, 'children'>, wizardProps?: Partial<WizardProps>) {
		closeAllTooltips();

		if (this._onOpen) {
			this._onOpen();
		}

		return new Promise(resolve => {
			this._resolve = resolve;
			this._modal = AppModal.open(
				() => <WizardForm process={this} {...wizardProps} />,
				{
					title: this._title,
					width: 1000,
					wrapClassName: 'process-form-modal',
					...modalProps,
					closable: false,
				},
			);
		});
	}

	public removeStep<T extends typeof AbstractLightForm>(form: T) {
		this._steps = this._steps.filter(s => s.form !== form);

		if (this.forceUpdate) {
			this.forceUpdate();
		}

		return this;
	}

	public setHasSucceed(value = true) {
		this._hasSucceed = value;
		return this;
	}

	public get steps() {
		return [...this._steps];
	}
}

class FormStore {
	private _items: FormStoreItem[] = [];

	public constructor() {
		// Fermeture de toutes les Modals quand l'url change
		browserHistory.listen(() => this._items.forEach(i => this.close(i.FormComponent)));
	}

	public close(FormComponent: typeof AbstractForm) {
		const item = this.get(FormComponent);

		if (item) {
			item.formInstance.close();
			_remove(this._items, i => i === item);
		}
	}

	public createWizard(title: string) {
		return new FormWizard(title);
	}

	public get(FormComponent: typeof AbstractForm) {
		return this._items.reverse().find(item => item.FormComponent === FormComponent);
	}

	public open<T extends typeof AbstractForm>(
		FormComponent: T,
		props: FormStoreItemProps<T>,
		modalProps: Omit<IFormModalProps, 'children'> = {},
	) {
		const customModalProps = { ...modalProps };

		closeAllTooltips();

		if (this._items.length) {
			const lastItem = this._items[this._items.length - 1];
			const margin = 10;

			if (!modalProps.width) {
				if (typeof lastItem.formInstance.modalProps.width === 'number') {
					customModalProps.width = parseInt(lastItem.formInstance.modalProps.width.toString()) - margin * 2;
				}
			}

			if (!modalProps.style?.top) {
				customModalProps.style = {
					...customModalProps.style,
					top: parseInt(lastItem.formInstance.modalProps.style?.top?.toString() || '100') + margin,
				};
			}
		}

		return new Promise(resolve => {
			const formInstance = new FormInstance(FormComponent, customModalProps, props, v => resolve(v));
			this._items.push({ FormComponent, formInstance });
		});
	}
}

const DragModal = (props) => {
	const dragRef = React.createRef<HTMLDivElement>();
	const [bounds, setBounds] = React.useState({ bottom: 0, left: 0, right: 0, top: 0 });
	const disabled = false;

	const onStart = (event, uiData) => {
		const { clientHeight, clientWidth } = window.document.documentElement;
		const targetRect = dragRef.current?.getBoundingClientRect();

		if (!targetRect) {
			return;
		}

		setBounds({
			bottom: clientHeight - (targetRect.bottom - uiData.y),
			left: -targetRect.left + uiData.x,
			right: clientWidth - (targetRect.right - uiData.x),
			top: -targetRect.top + uiData.y,
		});
	};

	return (
		<Draggable bounds={bounds} disabled={disabled} handle=".ant-modal-confirm-title" onStart={onStart}>
			<div ref={dragRef}>{props.children}</div>
		</Draggable>
	);
};

const formStore = new FormStore();
export default formStore;
